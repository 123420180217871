<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Compras</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Proveedores</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <Toast />
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Proveedores
          <Badge size="xlarge" severity="success"> {{ totalRecords }}</Badge>
        </div>
      </div>
      <div class="mt-3 lg:mt-0">
        <Button
          label="Nuevo"
          v-if="'Proveedor Crear' in auth.user.permissions"
          class="p-button-outlined mr-2 p-button-lg"
          v-tooltip.top="'Nuevo Proveedor'"
          icon="pi pi-plus"
          @click="openNuevo"
        ></Button>
      </div>
    </div>
    <div class="mt-2">
      <DataTable
        ref="dtproveedores"
        dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Proveedores"
        responsiveLayout="scroll"
        filterDisplay="menu"
        class="p-datatable-sm"
        :globalFilterFields="[
          'id',
          'nombre_completo',
          'tipo_proveedor.label',
          'ci_nit',
        ]"
        :value="proveedores"
        :rowHover="true"
        :loading="cargando"
        :lazy="true"
        :totalRecords="totalRecords"
        :paginator="true"
        :rows="10"
        :filters="buscar"
        v-model:filters="buscar"
        :rowsPerPageOptions="[10, 50, 100]"
        @page="onPage($event)"
        @sort="onSort($event)"
        @filter="onFilter($event)"
        stripedRows
        showGridlines
        @row-dblclick="editProveedor($event.data)"
      >
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>

        <Column
          field="id"
          header="CÓDIGO"
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
          :sortable="true"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="Código proveedor"
            />
          </template>
        </Column>
        <Column
          field="nombre_completo"
          header="NOMBRE COMPLETO"
          :sortable="true"
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="Nombre completo"
            />
          </template>
        </Column>
        <Column field="tipo_proveedor" header="TIPO PROVEEDOR">
          <template #body="{ data }">
            {{ data.tipo_proveedor.label }}
          </template>
        </Column>
        <Column
          field="ci_nit"
          header="CI/NIT"
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="CI / NIT"
            />
          </template>
        </Column>
        <Column field="telefono" header="TELÉFONO">
          {{ data.telefono }}
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado.value]">
              {{ data.estado.label }}
            </span>
          </template>
        </Column>
        <Column header="ACCIONES" style="width: 5rem">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised p-button-rounded p-button-info"
              v-tooltip.top="'Ver Acciones'"
              icon="pi pi-ellipsis-v"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog
      v-model:visible="deleteProveedorDialog"
      :style="{ width: '450px' }"
      header="Confirmar"
      :modal="true"
    >
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="proveedor"
          >Estas Seguro que quieres eliminar proveedor
          <b>{{ proveedor.nombre_completo }}</b> ?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-secondary"
          @click="deleteProveedorDialog = false"
        />
        <Button
          label="Si"
          icon="pi pi-check"
          class="p-button-primary"
          @click="deleteProveedor"
        />
      </template>
    </Dialog>
    <ProveedorCreate
      :show="proveedorDialog"
      :proveedor="proveedor"
      @closeModal="ocultarDialog"
      @actualizarListado="cargarProveedores"
    >
    </ProveedorCreate>
  </div>
</template>

<script>
import { FilterMatchMode /* , FilterOperator */ } from "primevue/api";
import ProveedorService from "@/service/ProveedorService";
import { useAuth } from "@/stores";
import ProveedorCreate from "@/module/proveedores/ProveedorCreate.vue";

export default {
  components: {
    ProveedorCreate,
  },
  data() {
    return {
      proveedores: null,
      cargando: true,
      totalRecords: 0,
      proveedorDialog: false,
      deleteProveedorDialog: false,
      proveedor: {},
      buscar: null,
      enviado: false,
      errors: {},
      lazyParams: {},
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  proveedorService: null,
  auth: null,
  created() {
    this.proveedorService = new ProveedorService();
    this.auth = useAuth();
    this.initBuscar();
  },
  mounted() {
    this.lazyParams = {
      first: 0,
      rows: this.$refs.dtproveedores.rows,
      sortField: null,
      sortOrder: null,
      filters: this.buscar,
    };

    this.cargarProveedores();
  },
  methods: {
    acciones(datos) {
      let permisosUsuarioAuth = this.auth.user.permissions;
      return [
        {
          label: "Editar",
          disabled: "Proveedor Editar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-pencil",
          command: () => {
            this.editProveedor(datos);
          },
        },
        {
          label: "Ver Detalle",
          disabled:
            "Proveedor Ver Detalle" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-eye",
          command: () => {
            this.verDetalle(datos);
          },
        },
        {
          label: "Eliminar",
          disabled: "Proveedor Eliminar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-trash",
          command: () => {
            this.confirmDeleteProveedor(datos);
          },
        },
      ];
    },
    cargarProveedores() {
      this.cargando = true;

      setTimeout(() => {
        this.proveedorService
          .getProveedoresAll({ dt_params: JSON.stringify(this.lazyParams) })
          .then((data) => {
            this.totalRecords = data.proveedores.total;
            this.proveedores = data.proveedores.data;
            this.cargando = false;
            this.proveedores.forEach((proveedor) => {
              proveedor.created_at = new Date(proveedor.created_at);
              proveedor.updated_at = new Date(proveedor.updated_at);
              proveedor.estado = {
                label: this.textoEstado(proveedor.estado),
                value: proveedor.estado,
              };
              proveedor.tipo_proveedor = {
                label: this.textoTproveedor(proveedor.tipo_proveedor),
                value: proveedor.tipo_proveedor,
              };
            });
          });
      }, Math.random() * 1000 + 250);
    },
    onPage(event) {
      this.lazyParams = event;
      this.cargarProveedores();
    },
    onSort(event) {
      this.lazyParams = event;
    },
    onFilter() {
      this.lazyParams.filters = this.buscar;
      this.cargarProveedores();
    },
    openNuevo() {
      this.proveedor = {
        estado: { label: "Activo", value: 1 },
        tipo_proveedor: { label: "Natural", value: 1 },
      };
      this.enviado = false;
      this.proveedorDialog = true;
    },
    ocultarDialog() {
      this.proveedorDialog = false;
      this.enviado = false;
    },

    ocultalModalProveedor() {
      this.proveedorDialog = false;
      this.proveedore = {};
    },
    textoEstado(estado) {
      return estado == 1 ? "Activo" : "Inactivo";
    },
    textoTproveedor(tipo_proveedor) {
      return tipo_proveedor == 1 ? "Natural" : "Juridico";
    },
    editProveedor(proveedor) {
      this.proveedor = { ...proveedor };
      this.proveedorDialog = true;
    },
    confirmDeleteProveedor(proveedor) {
      this.proveedor = { ...proveedor };
      this.deleteProveedorDialog = true;
    },
    deleteProveedor() {
      this.proveedorService.deleteProveedor(this.proveedor).then((data) => {
        if (data.status == 200) {
          this.proveedores = this.proveedores.filter(
            (val) => val.id !== this.proveedor.id
          );
          this.$toast.add({
            severity: "success",
            summary: "Exito",
            detail: data.mensaje,
            life: 3000,
          });
          this.cargarProveedores();
          this.deleteProveedorDialog = false;
          this.proveedor = {};
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: data.mensaje,
            life: 3000,
          });
          this.deleteProveedorDialog = false;
        }
      });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.proveedores.length; i++) {
        if (this.proveedores[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    initBuscar() {
      this.buscar = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        /* id: {
          operator: FilterOperator.EQUALS,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        }, */
        nombre_completo: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ci_nit: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    verDetalle(proveedor) {
      this.$router.push(`/proveedor/${proveedor.id}/detalle`);
    },
    formatDate(value) {
      return value.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
